.tableContainer {
  height: 536px;
}

.table {
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    button {
      border: none;
      background-color: transparent;
      margin-top: -12px;
      margin-bottom: -12px;
      padding: 0;
    }
  }
}

.popover {
  height: 245px;
}
