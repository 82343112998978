@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  .inner {
    flex: 1;
    display: flex;
    justify-content: space-between;
    min-height: 140px;
    max-height: 140px;

    .validationContainer {
      display: flex;
      flex-direction: column;
    }

    .validationRow {
      flex-direction: row;
      flex: 1;
      display: flex;

      div {
        font-size: 14px;
        line-height: 20px;

        &:first-child {
          width: 88px;
        }

        &:last-child {
          margin-left: 54px;
        }
      }
    }

    .validationRowDisabled {
      opacity: 0.67;

      div {
        &:last-child {
          visibility: hidden;
        }
      }
    }
  }
}
