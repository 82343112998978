@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;

  > div:first-child {
    height: 100%;
  }

  .tableRowInvalid {
    background: rgba(220, 0, 50, 0.15) !important;
    border-bottom: 1px solid rgba(220, 0, 50, 0.2) !important;

    &:hover {
      background: rgba(220, 0, 50, 0.3) !important;
      border-bottom: 1px solid rgba(220, 0, 50, 0.7) !important;
    }
  }

  .tableRowActive {
    background: rgba(67, 141, 237, 0.15) !important;
    border-bottom: 1px solid #438ded !important;

    &:hover {
      background: rgba(67, 141, 237, 0.3) !important;
      border-bottom: 1px solid rgba(67, 141, 237, 0.7) !important;
    }
  }

  .tableRowInvalidActive {
    background: rgba(220, 0, 50, 0.15) !important;
    border-bottom: 1px solid rgba(220, 0, 50, 0.7) !important;
  }

  .paginator {
    button:focus {
      background-color: $brown;
      border: none;
    }
  }
}
