@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.column {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  display: flex;

  .textarea {
    height: 91px;
    resize: none;
  }

  :global .mydpd-postcode-finder-container {
    .rbt-input-main {
      padding-right: 0;
    }

    .find-button {
      width: 48px;
      margin-left: 13px;
    }
  }
}
