@import "~@dpdgroupuk/mydpd-ui/styles/variables";

div.modal {
  max-width: 557px;

  > div {
    width: 557px;
  }
}

.radioInput {
  text-transform: capitalize;
  margin-left: 8px;

  input {
    height: 15px;
  }
}

p.subHeader {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.0025em;
  color: $black;
}

.fileInput {
  max-width: 282px;
}

.fileInputContainer {
  justify-content: flex-end;
  gap: 8px;
}

.progress {
  width: 100%;
}

.error {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  text-transform: capitalize;
  margin: 0;
  color: $red;
}

:global .snackbar-message {
  white-space: pre-line;
}
