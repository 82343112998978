@import "../../../../node_modules/@dpdgroupuk/mydpd-ui/styles/variables";

.container {
  min-height: calc(100% - #{$grid-gutter-width});
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 28px;
  padding-bottom: 8px;

  .lastChild {
    margin-left: 16px;
  }
}

.tableContainer {
  min-height: 400px;
  flex-grow: 1;
  flex-basis: 0;
}

.table {
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.invalidTableRow {
  background: rgba(220, 0, 50, 0.1);
  border-bottom: 1px solid rgba(220, 0, 50, 0.2);

  &:hover {
    background: rgba(220, 0, 50, 0.3) !important;
    border-bottom: 1px solid rgba(220, 0, 50, 0.7) !important;
  }
}

.popover {
  height: 245px;
}
