@import "../../../../../../node_modules/@dpdgroupuk/mydpd-ui/styles/variables";

.accountContainer {
  padding: 0;

  :global .col-md-5 {
    padding: 0 3px;
    flex: 0 0 46.67%;
    max-width: 46.67%;
  }

  :global .col-md-7 {
    padding: 0 3px;
    flex: 0 0 53.33%;
    max-width: 53.33%;
  }

  .row {
    margin: 0 -3px;
  }

  .checkboxCol {
    height: 40px;
  }
}

.searchInput {
  text-transform: uppercase;
}
