@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.label {
  display: flex;
  align-items: center;
  color: $gray_brown;
  cursor: pointer;
  font-size: 14px;

  input {
    margin-right: 15px;
    box-shadow: none;
  }

  input[type="radio"]:checked::after {
    background-color: $red;
    box-shadow: inset 0 0 0 2.5px #fff;
    border: solid 1px $red;
  }
}
