.container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;

  .fileInput {
    display: none;
  }

  .fileInputButton {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: #fff;
    padding: 5px 11px;
    width: 86px;
    height: 24px;
    background: #414042;
    cursor: pointer;
  }
}
