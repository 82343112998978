@import "~normalize.css/normalize.css";
@import "~@dpdgroupuk/mydpd-ui/styles/variables";

$grid-gutter-width: 14px;

// Input
$input-color: $black;
$input-bg: #fff;
$input-padding-y: 5px;
$input-padding-x: 10px;
$input-font-size: 14px;
$input-border-width: 0;
$input-height: 30px;

// Forms
$form-group-margin-bottom: 18px;
$form-text-margin-top: 0;

// Custom select
$custom-select-height: 30px;
$custom-select-padding-y: 5px;
$custom-select-padding-x: 10px;
$custom-select-indicator-padding: 20px;
$custom-select-font-size: 14px;
$custom-select-focus-border-color: $blue_1;
$custom-select-focus-box-shadow: none;
$custom-select-disabled-bg: #fff;
$custom-select-line-height: 14px;
$custom-select-box-shadow: none;

@import "~react-toggle/style.css";
@import "~@dpdgroupuk/mydpd-ui/styles/index";

.cursor-pointer {
  cursor: pointer;
}

.error-container {
  width: 257px;
  border: 0;
}

#postcode-finder-modal,
.mydpd-postcode-finder-container {
  .material-form-group {
    margin-top: 0;
  }
}

.mydpd-navbar-secondary-menu {
  margin-left: -80px;
}

.mydpd-btn-group {
  flex-wrap: nowrap;
}

.mydpd-dndtable-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
