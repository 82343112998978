.containerDrag {
  position: relative;
  display: flex;
  width: 565px;
  justify-content: space-between;
}

.dndCard {
  max-width: 231px;
}

.dragIcon {
  height: 27px;
  align-self: center;
}

.dragIconDisabled {
  opacity: 0.38;
}

.item {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.disabledItem {
  pointer-events: none;
}
