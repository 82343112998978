.container {
  border: none;
  height: 32px;
  padding-top: 2px;

  > div {
    display: flex;
    align-items: center;
    min-height: 100%;
  }

  input {
    box-shadow: none;
    height: 18px;
    width: 18px;
    margin-top: 0;
  }

  label {
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
  }

  &.readonly {
    opacity: 0.67;
  }

  &.disabled {
    opacity: 0.38;
  }
}
