@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.col {
  display: flex;
  flex-direction: column;
}

.cartContainer {
  min-height: 700px;
  max-height: 700px;
  height: 100%;
}

.tableContainer {
  display: flex;
  flex: 1;
  flex-basis: 0;
  flex-direction: column;

  .invalidTableRow {
    background: rgba(220, 0, 50, 0.1);
    border-bottom: 1px solid rgba(220, 0, 50, 0.2);

    &:hover {
      background: rgba(220, 0, 50, 0.3) !important;
      border-bottom: 1px solid rgba(220, 0, 50, 0.7) !important;
    }
  }
}

.table {
  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.popover {
  height: 495px;
}
