@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.modal {
  max-width: 692px;

  > div {
    width: 692px;
  }

  .modalBody {
    padding-top: 8px;
    padding-bottom: 25px;
  }
}

.table {
  display: flex;
  overflow: auto;
  max-height: 225px;

  .tableContainer {
    display: flex;
  }

  table {
    margin-bottom: 0;

    td {
      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;
    }
  }
}

.stats {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;

  .item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    p {
      margin: 0;
    }

    .label,
    .labelError,
    .labelWarning {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0071e3;
      color: #fff;
      min-width: 16px;
      border-radius: 4px;
      margin-left: 6px;
      padding: 1px 4px;

      &Error {
        background-color: #dc0032;
      }

      &Warning {
        background-color: #fe9400;
      }
    }
  }
}
