@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.header {
  font-size: 18px;
  margin-left: 5px;
  font-weight: bold;

  span {
    color: #4199d9;
  }
}
