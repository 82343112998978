@import "~@dpdgroupuk/mydpd-ui/styles/variables";

.defaultColors {
  .body {
    color: $black;
    border: 1px solid $black;

    div:hover {
      background: #d3d3d3;
    }
  }

  .label,
  .helperText {
    color: rgba(0, 0, 0, 0.67);
  }
}

.activeColors {
  .body,
  .label {
    color: $blue_1;
    border-color: $blue_1;
    background-color: #fff;
  }
}

.disabledColors {
  .body,
  .helperText,
  .label {
    opacity: 1;
    color: rgba(0, 0, 0, 0.38);
    border-color: rgba(0, 0, 0, 0.38);
  }
}

.readOnlyColors {
  .body,
  .helperText,
  .label {
    color: rgba(0, 0, 0, 0.67);
    border-color: rgba(0, 0, 0, 0.67);
  }

  input,
  textarea {
    border-color: rgba(0, 0, 0, 0.38);
  }
}

.errorColors {
  .body,
  .helperText,
  .label {
    color: $red !important;
    border-color: $red !important;
  }
}

.fieldContainer {
  width: 100%;
  margin-bottom: 18px;
  margin-top: 10px;
  display: flex;
  flex: 1;

  .field {
    position: relative;
    display: flex;
    flex: 1;
    max-width: 100%;

    .label {
      position: absolute;
      margin-left: 9px;
      font-size: 11px;
      line-height: 16px;
      padding: 0 5px;
      top: -10px;
      background: #fff;
      cursor: default;
    }

    .helperText {
      font-size: 10px;
      line-height: 18px;
      letter-spacing: 0.4px;
      bottom: -18px;
      right: 0;
      position: absolute;
      cursor: default;
    }

    .body {
      padding: 0.5rem;
      width: 100%;
      min-width: 11.62rem;
      font-size: 14px;
      border-radius: 4px;
      text-transform: uppercase;
      overflow-y: auto;
    }

    .placeholder {
      font-size: 14px;
      text-transform: uppercase;
      opacity: 0.67;
      color: $black;
    }
  }
}
